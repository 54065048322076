import React, { useState, useContext, useEffect } from "react";
import appContext from "../../context/appContext";
import mapTabContext from "../../context/mapTabContext";
import { Box, Button, Layer, Stack, Text, ThemeContext, Anchor } from "grommet";
import {
  getShapeFile,
  downloadReport as downloadReportPython,
} from "@headwaters-economics/web-shared";
// import { downloadReport as downloadReportDotNet } from "../../../../helpers/he-reportBuild";
// import { downloadReport as downloadReportPython } from "@headwaters-economics/web-shared/helpers/reportBuild";
import HeMapboxUber, {
  CensusLayer,
} from "@headwaters-economics/web-shared/components/Map";
import ReactGA from "react-ga";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faThumbsUp,
  faThumbsDown,
  faTimes,
  faFilePdf,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";
import appColors from "../../appColors";

ReactGA.initialize("UA-3386980-1");

const customLayerTheme = {
  layer: {
    zIndex: 20,
    container: {
      zIndex: 25,
    },
  },
};

const DownloadModal = () => {
  const { selectedLocation, selectedLocationMetadata } = useContext(appContext);
  const {
    tracts,
    selectedLocationExtent,
    downloadModalProps,
    setDownloadModalProps,
  } = useContext(mapTabContext);
  const [progressIndicatorContent, set_progressIndicatorContent] = useState(
    <Text size="xlarge" color="white">
      <FontAwesomeIcon icon={faSpinner} spin />
    </Text>
  );
  const [printMapRef, set_printMapRef] = useState(null);
  const [mapLoaded, set_mapLoaded] = useState(false);

  useEffect(() => {
    if (
      downloadModalProps &&
      window.location.hostname === "headwaterseconomics.org"
    ) {
      ReactGA.pageview(window.location.pathname + "/download");
    } else {
      set_mapLoaded(false);
    }
  }, [downloadModalProps]);

  useEffect(() => {
    if (mapLoaded) {
      set_progressIndicatorContent(null);
    } else {
      set_progressIndicatorContent(
        <Text size="xlarge" color="white">
          <FontAwesomeIcon icon={faSpinner} spin />
        </Text>
      );
    }
  }, [mapLoaded]);

  if (!downloadModalProps) return null;

  const visibleTractIds = tracts.filter((e) => e.include).map((e) => e.id);
  const downloadType =
    downloadModalProps.tracts.length === 1 &&
    "type" in downloadModalProps.tracts[0] &&
    downloadModalProps.tracts[0].type === "mapClick"
      ? "mapClick"
      : "allSelected";

  const selectedFeatures =
    downloadType === "mapClick"
      ? [{ id: downloadModalProps.tracts[0].id, geo_level: "tract" }]
      : tracts
          .filter((tract) => tract.include)
          .map((tract) => tract.id)
          .map((tract) => ({
            id: tract,
            geo_level: "tract",
          }));

  const onDownload = (reportFormat) => {
    set_progressIndicatorContent(
      <Text size="large" color="white">
        <FontAwesomeIcon icon={faSpinner} spin />
        Download in progress
      </Text>
    );

    const parameters = {
      report_template_name: "nar",
      report_sub_heading:
        downloadModalProps.tracts.length === 1
          ? downloadModalProps.tracts[0].name
          : "Selected Tracts",
      report_format: reportFormat,
      request_source: "neighborhoods-at-risk",
      position_keys: [
        {
          position_key: 1,
          geo_codes: selectedLocation.id,
          // override_name: "",
          is_aggregate: false,
          is_region: false,
          is_benchmark: false,
        },
        {
          position_key: 2,
          geo_codes:
            downloadType === "mapClick"
              ? downloadModalProps.tracts[0].id
              : visibleTractIds.join(","),
          override_name:
            downloadModalProps.tracts.length === 1
              ? downloadModalProps.tracts[0].name
              : "Combined Tracts",
          is_aggregate: false,
          is_region: true,
          is_benchmark: false,
        },
        {
          position_key: 3,
          geo_codes: "0",
          override_name: "U.S.",
          is_aggregate: false,
          is_region: false,
          is_benchmark: true,
        },
      ],
      base64_graphics: [
        {
          name: "base64_map_png",
          base64_string: printMapRef
            .getMap()
            .getCanvas()
            .toDataURL()
            .split(",")[1],
        },
      ],
    };

    downloadReportPython(parameters, {
      fileName: "Neighborhoods-At-Risk",
      auth_key: "58f0d42d-0ed7-493d-afe4-f042d5c3aac3",
    }).then(
      () => {
        set_progressIndicatorContent(
          <Text size="large" color="white">
            <FontAwesomeIcon icon={faThumbsUp} />
            Check your download folder!
          </Text>
        );
        setTimeout(() => {
          set_progressIndicatorContent(null);
          setDownloadModalProps(null);
        }, 3000);
      },
      (err) => {
        set_progressIndicatorContent(
          <Text size="large" color="white">
            <FontAwesomeIcon icon={faThumbsDown} />
            Looks like there was an problem!
          </Text>
        );
        setTimeout(() => {
          set_progressIndicatorContent(null);
          setDownloadModalProps(null);
        }, 3000);
      }
    );
  };

  const onShapefileDownload = () => {
    set_progressIndicatorContent(
      <Text size="large" color="white">
        <FontAwesomeIcon icon={faSpinner} spin />
        Download in progress
      </Text>
    );
    getShapeFile(
      "getNarShapeFile",
      selectedLocationMetadata.label.replace(/[^a-z0-9]/gi, "_").toLowerCase(),
      tracts.map((tract) => tract.id),
      selectedLocationMetadata.label,
      document.location.href
    ).then(
      () => {
        set_progressIndicatorContent(
          <Text size="large" color="white">
            <FontAwesomeIcon icon={faThumbsUp} />
            Check your download folder!
          </Text>
        );
        setTimeout(() => {
          set_progressIndicatorContent(null);
          setDownloadModalProps(null);
        }, 3000);
      },
      (err) => {
        set_progressIndicatorContent(
          <Text size="large" color="white">
            <FontAwesomeIcon icon={faThumbsDown} />
            Looks like there was an problem!
          </Text>
        );
        setTimeout(() => {
          set_progressIndicatorContent(null);
          setDownloadModalProps(null);
        }, 3000);
      }
    );
  };

  const DownloadButton = ({ format }) => {
    return (
      <Button
        fill
        plain
        gap="xsmall"
        icon={
          format === "pdf" ? (
            <FontAwesomeIcon icon={faFilePdf} />
          ) : (
            <FontAwesomeIcon icon={faFileExcel} />
          )
        }
        onClick={() => {
          onDownload(format);
        }}
        label={format === "pdf" ? "PDF" : "Excel"}
      />
    );
  };

  return (
    <>
      <div
        style={{
          marginTop: "100000px",
          position: "absolute",
          width: parseInt(500 / window.devicePixelRatio, 10) + "px",
          height: parseInt(500 / window.devicePixelRatio, 10) + "px",
          backgroundColor: "red",
        }}
      >
        <HeMapboxUber
          mapStyle="mapbox://styles/headmin/cko4vy98008zg17o6e70xacpy"
          extent={selectedLocationExtent}
          onMapLoad={(mr) => {
            set_mapLoaded(true);
            set_printMapRef(mr.current);
          }}
          enableExport={true}
        >
          <CensusLayer
            layerName={"tract"}
            filterIds={tracts.map((tract) => tract.id)}
            before="road-label-small"
            before_hoverLayer="road-label-medium"
            selectedFeatures={selectedFeatures}
            color_default_border={appColors.excluded_border}
            color_default_fill={appColors.excluded}
            color_select_border={appColors.included_border}
            color_select_fill={appColors.included}
            lineWidth_hoverLayer={3}
          />
        </HeMapboxUber>
      </div>
      <ThemeContext.Extend value={customLayerTheme}>
        <Layer
          responsive={false}
          modal={true}
          onEsc={() => setDownloadModalProps(null)}
          onClickOutside={() => setDownloadModalProps(null)}
          margin={"10px"}
        >
          <Box width={{ max: "500px" }}>
            <Stack>
              <Box height="300px" overflow="hidden" justify="between">
                <Box
                  direction="row"
                  justify="between"
                  align="center"
                  background="dark-1"
                  pad="xsmall"
                >
                  <Text weight="bold">Download Report</Text>
                  <Anchor
                    title="close window"
                    onClick={() => setDownloadModalProps(null)}
                  >
                    <FontAwesomeIcon icon={faTimes} size="lg" />
                  </Anchor>
                </Box>
                <Box pad="small">
                  <Text>{downloadModalProps.label}</Text>
                  <Box
                    justify="end"
                    direction="row"
                    align="center"
                    gap="xsmall"
                  >
                    <Box>
                      <Text>Report format:</Text>
                    </Box>
                    <Box direction="row">
                      <Box
                        fill
                        background={{
                          color: "heRed",
                        }}
                        pad={{
                          vertical: "xsmall",
                          left: "small",
                          right: "xsmall",
                        }}
                        round={{ size: "small", corner: "left" }}
                      >
                        <DownloadButton format="pdf" report="report" />
                      </Box>
                      <Box
                        fill
                        background="heGreen"
                        pad={{
                          vertical: "xsmall",
                          left: "xsmall",
                          right: "small",
                        }}
                        round={{ size: "small", corner: "right" }}
                      >
                        <DownloadButton format="xlsx" report="report" />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  fill="horizontal"
                  flex="shrink"
                  pad="small"
                  background="light-3"
                  border={{ side: "top", size: "1px", color: "light-4" }}
                >
                  <Text size="small" fill>
                    The data associated with all tracts in{" "}
                    {selectedLocationMetadata.label} is also available for
                    download.
                  </Text>
                  <Box width="150px" alignSelf="end">
                    <Button
                      round="xsmall"
                      onClick={onShapefileDownload}
                      label={
                        <Text weight="normal" size="small">
                          Shapefile
                        </Text>
                      }
                      size="small"
                      color="light-1"
                    />
                  </Box>
                </Box>
              </Box>

              {progressIndicatorContent && (
                <Box
                  fill
                  height="300px"
                  background="#929292ba"
                  align="center"
                  justify="center"
                >
                  {progressIndicatorContent}
                </Box>
              )}
            </Stack>
          </Box>
        </Layer>
      </ThemeContext.Extend>
    </>
  );
};
export default DownloadModal;
